document.addEventListener('turbolinks:load', () => {
  const allowQuestionnaireSubmit = () => {
    const type = document.querySelector('.question-input.mandatory-question .input-text').type;
    let hasAnswer = false;

    let input = '';
    if (type === 'text' || type === 'number') {
      input = document.querySelector('.question-input.mandatory-question .input-text').value;
      if (input !== '') {
        hasAnswer = true;
      }
    } else if (type === 'radio' || type === 'checkbox') {
      input = document.querySelector('.question-input.mandatory-question .input-text:checked');
      if (input !== null) {
        hasAnswer = true;
      }
    }

    const submitBtn = document.querySelector('input[type="submit"]');
    if (hasAnswer) {
      submitBtn.classList.add('btn-primary');
      submitBtn.classList.remove('btn-disabled');
    } else {
      submitBtn.classList.remove('btn-primary');
      submitBtn.classList.add('btn-disabled');
    }
  };
  document.querySelectorAll('.question-input.mandatory-question .input-text').forEach((input) => {
    input.addEventListener('change', () => {
      allowQuestionnaireSubmit();
    });
  });
});
