document.addEventListener('turbolinks:load', function() {
  const showInformedParents = (userAgeField) => {
    const userInformedParentsField = document.querySelector('#user-informed-parents-field');
    if (userAgeField.value >= 14) {
      userInformedParentsField.style.display = 'none';
    } else {
      userInformedParentsField.style.display = 'block';
    }
  };

  const changeLoginType = (loginTypeContainer) => {
    const loginType = loginTypeContainer.querySelector('input:checked').value;
    const userInformedParentsField = document.querySelector('#user-informed-parents-field');
    if (loginType === 'minor') {
      showInformedParents(document.querySelector('#user-age-field'));
    } else {
      userInformedParentsField.style.display = 'none';
    }
  };

  const loginTypeContainer = document.querySelector('.login-type');
  if (loginTypeContainer != null) {
    loginTypeContainer.addEventListener('click', () => {
      changeLoginType(loginTypeContainer);
    });
    changeLoginType(loginTypeContainer);
  }

  const userAgeField = document.querySelector('#user-age-field');
  if (userAgeField != null) {
    userAgeField.addEventListener('change', () => {
      showInformedParents(userAgeField);
    });
    showInformedParents(userAgeField);
  }
});
