import { tns } from 'tiny-slider';

document.addEventListener('turbolinks:before-visit', function() {
  if (window.tSlider != null && window.tSlider.isOn) {
    window.tSlider.destroy();
  }
  if (window.tSlider2 != null && window.tSlider2.isOn) {
    window.tSlider2.destroy();
  }
});

document.addEventListener('turbolinks:load', function() {
  const slider = document.querySelector('.carousel-slider');

  if (slider !== null) {
    if (window.tSlider != null && window.tSlider.isOn == null) {
      window.tSlider = window.tSlider.rebuild();
    } else {
      window.tSlider = tns({
        container: '.carousel-slider',
        items: 1,
        autoplay: true,
        controls: false,
        nav: true,
        navPosition: 'bottom',
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 0
      });
    }
  }

  const slider2 = document.querySelector('.carousel-slider-2');
  if (slider2 !== null) {
    if (window.tSlider2 != null && window.tSlider.isOn == null) {
      window.tSlider2 = window.tSlider2.rebuild();
    } else {
      window.tSlider2 = tns({
        container: '.carousel-slider-2',
        items: 1,
        autoplay: true,
        controls: false,
        nav: true,
        navPosition: 'bottom',
        autoplayButtonOutput: false,
        mouseDrag: true,
        gutter: 0,
        responsive: {
          900: {
            items: 2
          }
        }
      });
    }
  }
});
